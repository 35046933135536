import { concatValues } from "../shared/utils/helpers"

/**
 * @constant { Array.<category> } sessionCategories
 * {object} category
 * {string} category.label
 * {string} category.value
 * 
 */
export const sessionCategories = [
  {label:'Pre Disclosure', value:'pre_disclosure'},
  {label:'Initial Disclosure', value:'disclosure'},
  {label:'Re Disclosure', value:'redisclosure,tla_redisclosure'},
  {label:'Adverse Action', value:'adverse_action'},
  {label:'Appraisal', value:'appraisal'},
  {label:'Processing', value:'processing'},
  {label:'Underwriting', value:'underwriting'},
  {label:'Pre Closing', value:'preclosing'},
  {label:'Closing', value:'closing'},
  {label:'Shipping', value:'shipping'}
]

/**
 * @const concatSessionCategories
 * @returns {string} concatenated session category values
 * @see sessionCategories
 */
export const concatSessionCategories = concatValues(sessionCategories, 'value')

/**
 * @const {array} mergeSessionCategories
 * @see concatSessionCategories
 */
export const mergeSessionCategories = [{
  label: 'all',
  value: concatSessionCategories
}]